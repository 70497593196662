<template>
    <AboutUs :item="page" />
</template>
<script>

import AboutUs from '../../components/AboutUs';
import { PAGE_KEYS } from '../../utils';
import { /*mapGetters,*/ mapActions } from 'vuex';
export default {
    data: () => ({
        page: {
            banner: null,
            text: null
        },
        loadingPage: false,
    }),
    created() {
        //this.loadAbout({ code: 'quem-somos' });
        this.loadingPage = true;
        this.loadPage({ key : PAGE_KEYS.projeto }).then((function (page) {
            this.page = page;
        }).bind(this)).catch(() => {
        }).finally(() => this.loadingPage = false)
    },
    //computed: mapGetters(['projects']),
    //methods: mapActions(['loadAbout']),
    methods: { 
        ...mapActions(['loadPage']),
    },
    components: {
        AboutUs,
    }    
}
</script>